@import "../../index.css";

.Message {
  width: 80%;
  margin: auto;
  h2 {
    padding: 20px;
    color: #683636;
  }
}
