@import "../../index.css";

.Cards {
  padding-left: 20px;
  padding-right: 20px;
  width: 80%;
  margin: auto;
  h2 {
    padding: 5px 20px;
    margin: 10px 0;
    color: rgb(103, 71, 71);
  }
  .Card {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    margin: 20px 0;
    background-color: rgb(253, 249, 248);
    font-size: 15px;
    border-radius: 12px;
    h3 {
      padding: 5px;
      color: rgb(119, 54, 54);
    }
  }
}
